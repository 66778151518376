.title-bar {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 25px 0px;
  padding: 5px 10px 5px 10px;
}

.title-bar-customer {
  display: flex;
  margin: 0px 0px 25px 0px;
  padding: 5px 10px 5px 10px;
  border-radius: 2px;
  background-color: #25a18e;
}
.title-bar.border {
  /* border: 2px solid black; */
  border-radius: 2px;
}
.title-bar.background {
  background-color: #25a18e;
}
