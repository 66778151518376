.label-products {
  font-size: large;
  font-weight: bold;
  padding-left: 10px;
  text-align: left;
}

.dropdown {
  padding-right: 10px;
  padding-top: 10px;
  text-align: right;
}

.products-top {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: x-large;
  font-weight: bold;
  padding-left: 10px;
  text-align: left;
}