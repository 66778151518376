.align-right {
  padding-right: 10px;
  text-align: right;
}
.set-title {
  display: flex;
  /* justify-content: space-between; */
  margin: 0px 0px 5px 0px;
  padding: 5px 5px 5px 5px;
  border-radius: 2px;
  background-color: #25a18e;
}
