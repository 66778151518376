@import url(https://fonts.googleapis.com/css?family=Open+Sans);

body {
  height: 100%;
  background: #ffff;
  font-family: "Open Sans", Helvetica, sans-serif;
}

a,
a:hover {
  text-decoration: none;
}

input:focus {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

li {
  list-style-type: none;
}
a {
  color: rgb(29, 27, 27);
}

.links:hover {
  background: darken(#34495e, 10%);
}

.search input {
  width: 394px;
  height: 50px;
  margin-right: 30px;
  border: 3px solid #412b69;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
  margin: 4px;
}
.search {
  display: flex;
  justify-content: right;
}

.sellables-container {
  display: flex;
  /* height: 100%; */
  flex: 2;
}
.sellables {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.5em;
}
.category {
  padding: 17.5px;
  margin: 2px;
  border: 1px solid darken(#e6e9ed, 10%);
  border-radius: 5px;
  background: #e6e9ed;
  color: #424a54;
}

.category:hover {
  background: darken(#0f1113, 10%);
}

.active {
  background: darken(#14171a, 15%);
  color: #424a54;
}

.active:hover {
  background: darken(#e6e9ed, 20%);
}

.item-group-wrapper {
  overflow-y: scroll;
  /* height: 70vh; */
}
.item-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.item {
  padding: 15px 20px 0px 15px;
  margin: 1.5em 0.5em;
  border-radius: 5px;
  background: white;
  color: black;
  cursor: pointer;
}

.item:hover {
  background: darken(#9b59b6, 10%);
}

.register-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.customer input {
  height: 40px;
  width: 394px;
  margin-top: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
}

.customer input::placeholder {
  font-style: italic;
}

.register {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin: 1em 2em;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background: #fff;
}
.products {
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
}
.product-bar {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #fff;
}

.product-bar:first-child {
  margin-top: 1em;
}

.product-bar:hover {
  background: darken(#fff, 10%);
}

.selected {
  background: #412b69;
  color: #fff;
}

.selected:hover {
  background: darken(#68b3c8, 10%);
}

.pay-button {
  display: flex;

  align-items: center;
  justify-content: center;
}
.links {
  padding: 10px 125px;
  margin: 1em 0;
  border-radius: 5px;
  background: #e6e9ed;
  color: rgb(43, 39, 39);
}

a:hover {
  background: darken(#42a07f, 5%);
}
