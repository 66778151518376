.label {
  padding: 0px 10px 0px 10px;
}
.link {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 10px 10px 0px;
}
.button {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 10px 10px 0px;
}
.spacing {
  margin: 10px 0px 10px 0px;
}
