/* .title-bar {
  display: flex;
  justify-content:"flex-start" ;
} */

.title-bar.border {
  /* border: 2px solid black; */
  border-radius: 2px;
}

.title-bar.background {
  background-color: #25a18e;
}
